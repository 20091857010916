import {CAMPAIGN_STATUS, MESSAGE_DEFINITION_INTERVAL, MESSAGE_DEFINITION_STATUS} from "./const";
import {getString} from "Core/helpers/data";
import {allTimezones} from "react-timezone-select";
import {get} from "lodash";
import {v4} from 'uuid';

/**
 * Data object representing the campaign list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class CampaignListItemDataObject {
	/**
	 * @param {string} id - Campaign's DB ID.
	 * @param {string} name - Campaign's name.
	 * @param {CampaignStatus} status - Campaign's status.
	 * @param {Date} [startDate=null] - Campaign's stating date.
	 * @param {Date} [endDate=null] - Campaign's end date.
	 */
	constructor(id, name, status, startDate = null, endDate = null) {
		this.id = id;
		this.name = name;
		this.status = status;
		this.startDate = startDate;
		this.endDate = endDate;
	}
}

/**
 * Data object representing the campaign item
 * @note This is usually used when creating or updating data table item using a popup or a dialog. Params have default 
 * values since this data object can be used for creating an item. This is why 'id' has a default value of null which 
 * represents a new item that is being created.
 */
export class CampaignItemDataObject {
	/**
	 * @param {string|null} [id=null] - Campaign's DB ID.
	 * @param {string} projectId - Project's DB ID.
	 * @param {string} [name=''] - Campaign's name.
	 * @param {string} [description=''] - Campaign's description.
	 * @param {Date} [startDate=null] - Campaign's start date.
	 * @param {Date} [endDate=null] - Campaign's end date.
	 * @param {CampaignStatus} status - Campaign's status.
	 * @param {?{label: string, value: string, active: boolean}} [cappingPlan=null] - Capping plan for this campaign.
	 * @param {string|null} [clientId=null] - Client's DB ID used when sysadmin is working with the app as a client. This 
	 * property is set by the app (sysadmin selects a client to work with) and not loaded from IO (write-only).
	 */
	constructor(
		id = null, projectId, name = '', description = '', startDate = null, endDate = null, 
		status = CAMPAIGN_STATUS.IN_PREPARATION, cappingPlan = null, clientId = null,
	) {
		this.id = id;
		this.projectId = projectId;
		this.name = name;
		this.description = description;
		this.startDate = startDate;
		this.endDate = endDate;
		this.status = status;
		this.cappingPlan = cappingPlan;
		
		// Write-only properties
		this.clientId = clientId;
	}
}



export class MessageDefinitionDataObject {
	/**
	 * @param {?string} [id=null] - Message definition DB ID.
	 * @param {string} campaignId - DB ID of the campaign that this message definition belongs to.
	 * @param {boolean} [personalized=false] - Flag that specifies if message will be personalized to each recipient by
	 * using dynamic values like first and last name.
	 * @param {MessageDefinitionChannelDataObject[]} [channels=[]] - Message sending channels.
	 * @param {Date} [startDate=null] - Date from which messages created with this message definition will become active.
	 * @param {number} [messageCount=0] - Number of messages that will be sent in bulk on each interval.
	 * @param {MessageDefinitionInterval} [interval='DAY'] - Interval for sending messages (for example per hour or day).
	 * @param {MessageContentDataObject[]} [messageContents=[]] - List of all the different
	 * messages that can be sent by this message definition.
	 * @param {string} [deliveryStartTime=''] - Time of day when system will start delivering user messages.
	 * @param {string} [deliveryEndTime=''] - Time of day when system will stop delivering user messages.
	 * @param {number} [timeToLive] - Timeout (in minutes) for the delivery of messages.
	 * @param {number} [numberOfRetries] - Number of times system will try to send a customer message.
	 * @param {number} [pauseBetweenRetries] - Time (in minutes) that must elapse between retries.
	 * @param {string} [timeZoneId] - Timezone identifier (for example: 'Europe/Belgrade'). If not defined, current 
	 * browser's timezone will be used if it is available in the list of all supported timezones.  
	 * @param {MessageDefinitionStatus|''} [status=''] - Message definition status.
	 * @param {?number} [ordinal=null] - Message definition ordinal (read-only).
	 */
	constructor(
		id = null, campaignId, personalized = false, channels = [], startDate = null, messageCount = 0, 
		interval = MESSAGE_DEFINITION_INTERVAL.DAY, messageContents = [], deliveryStartTime = '', deliveryEndTime = '', 
		timeToLive, numberOfRetries, pauseBetweenRetries,
		timeZoneId = (
			get(allTimezones, getString(Intl.DateTimeFormat().resolvedOptions(), 'timeZone')) ?
				getString(Intl.DateTimeFormat().resolvedOptions(), 'timeZone') : 
				''
		),
		status = MESSAGE_DEFINITION_STATUS.IN_PREPARATION, ordinal = null,
	) {
		this.id = id;
		this.campaignId = campaignId;
		this.personalized = personalized;
		this.channels = channels;
		this.startDate = startDate;
		this.messageCount = messageCount;
		this.interval = interval;
		this.messageContents = messageContents;
		this.deliveryStartTime = deliveryStartTime;
		this.deliveryEndTime = deliveryEndTime;
		this.timeToLive = timeToLive;
		this.numberOfRetries = numberOfRetries;
		this.pauseBetweenRetries = pauseBetweenRetries;
		this.timeZoneId = timeZoneId;
		this.status = status;
		this.ordinal = ordinal;
	}
}

export class MessageDefinitionChannelDataObject {
	/**
	 * @param {string|null} [id=null] - Message definition channel's DB ID (ID form the relation table).
	 * @param {string|null} [channelId=null] - Channel's DB ID.
	 * @param {string} [channelName=''] - Name of the channel.
	 * @param {CampaignMessageContentType|''} [messageType=''] - Message type used by the channel.
	 * @param {?string} [sender=null] - For SMS and Viber messages this field is set to short code. For email messages 
	 * this field is set to the email address that email is sent from. For push notifications this field can be empty.
	 * @param {string} [senderName=''] - This field is populated only for email messages, and it is set to the name of 
	 * the sender ("from name") set on the email message when sending an email.
	 * @param {string} [GUIID] - GUI ID of the message definition channel used exclusively by GUI to manage the list of 
	 * channels.
	 */
	constructor(
		id = null, channelId = null, channelName = '', messageType = '', sender = null, senderName = '', GUIID = v4(),
	) {
		this.id = id;
		this.channelId = channelId;
		this.channelName = channelName;
		this.messageType = messageType;
		this.sender = sender;
		this.senderName = senderName;
		this.GUIID = GUIID;
	}
}



export class MessageContentDataObject {
	/**
	 * @param {string|null} [id=null] - DB ID of the message content item.
	 * @param {CampaignMessageContentType|''} [messageType=''] - Message type (SMS, Viber, etc.).
	 * @param {string} [subject=''] - Subject of the message.
	 * @param {string} [body=''] - Message body text.
	 * @param {any} [bodySource] - Message body source.
	 * @param {string} [image=''] - URL of the image that will be added below the message body.
	 * @param {string} [link=''] - Link used for the button that will be added to the bottom of the message.
	 * @param {string} [linkText=''] - Label of the button that will be added to the bottom of the message.
	 * @param {Object} [customFields={}] - Custom fields specified by the user that will be sent to mobile app alongside
	 * the message. Custom fields are not visible in the message preview.
	 */
	constructor(
		id = null, messageType = '', subject = '', body = '', bodySource, image = '', link = '', linkText = '', 
		customFields = {}
	) {
		this.id = id;
		this.messageType = messageType;
		this.subject = subject;
		this.body = body;
		this.bodySource = bodySource;
		this.image = image;
		this.link = link;
		this.linkText = linkText;
		this.customFields = customFields;
	}
}


export class ChannelListItemDataObject {
	/**
	 * @param {string} value - DB ID of the channel from the channel list.
	 * @param {string} label - Name of the channel from the channel list.
	 * @param {CampaignMessageContentType} messageType - Message type used by this channel from the channel list.
	 * @param {string} mobileAppName - Name of the mobile application if channel is for push notification. 
	 * @param {ChannelListItemSenderDataObject[]} senders - List of possible senders for the channel.
	 * @param {string} providerId - DB ID of the channel provider.
	 */
	constructor(value, label, messageType, mobileAppName, senders, providerId) {
		this.value = value;
		this.label = label;
		this.messageType = messageType;
		this.mobileAppName = mobileAppName;
		this.senders = senders;
		this.providerId = providerId;
	}
}

export class ChannelListItemSenderDataObject {
	/**
	 * @param {string} sender - Predefined channel sender defined for the channel provider.
	 * @param {string} senderName - Default channel sender name for the sender used for email channels. Can be overridden 
	 * by user.
	 */
	constructor(sender, senderName) {
		this.sender = sender;
		this.senderName = senderName;
	}
}