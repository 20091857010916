/** @typedef {string} CampaignStatus */
export const CAMPAIGN_STATUS_IN_PREPARATION = 'IN_PREPARATION';
export const CAMPAIGN_STATUS_ACTIVE = 'ACTIVE';
export const CAMPAIGN_STATUS_COMPLETED = 'COMPLETED';
export const CAMPAIGN_STATUS_EXPIRED = 'EXPIRED';
export const CAMPAIGN_STATUS_STOPPED = 'STOPPED';
/** @enum {CampaignStatus} */
export const CAMPAIGN_STATUS = {
	IN_PREPARATION: CAMPAIGN_STATUS_IN_PREPARATION,
	ACTIVE: CAMPAIGN_STATUS_ACTIVE,
	COMPLETED: CAMPAIGN_STATUS_COMPLETED,
	EXPIRED: CAMPAIGN_STATUS_EXPIRED,
	STOPPED: CAMPAIGN_STATUS_STOPPED,
};
/** @type {CampaignStatus[]} */
export const CAMPAIGN_STATUSES = [
	CAMPAIGN_STATUS_IN_PREPARATION,
	CAMPAIGN_STATUS_ACTIVE,
	CAMPAIGN_STATUS_COMPLETED,
	CAMPAIGN_STATUS_EXPIRED,
	CAMPAIGN_STATUS_STOPPED,
];


/** @typedef {string} CampaignMessageContentType */
export const CAMPAIGN_MESSAGE_CONTENT_TYPE_ALL = 'ALL';
export const CAMPAIGN_MESSAGE_CONTENT_TYPE_EMAIL = 'EMAIL';
export const CAMPAIGN_MESSAGE_CONTENT_TYPE_SMS = 'SMS';
export const CAMPAIGN_MESSAGE_CONTENT_TYPE_VIBER = 'VIBER';
export const CAMPAIGN_MESSAGE_CONTENT_TYPE_PUSH_NOTIFICATION = 'PUSH_NOTIFICATION';
/** @enum {CampaignMessageContentType} */
export const CAMPAIGN_MESSAGE_CONTENT_TYPE = {
	ALL: CAMPAIGN_MESSAGE_CONTENT_TYPE_ALL,
	EMAIL: CAMPAIGN_MESSAGE_CONTENT_TYPE_EMAIL,
	SMS: CAMPAIGN_MESSAGE_CONTENT_TYPE_SMS,
	VIBER: CAMPAIGN_MESSAGE_CONTENT_TYPE_VIBER,
	PUSH_NOTIFICATION: CAMPAIGN_MESSAGE_CONTENT_TYPE_PUSH_NOTIFICATION,	
};
/** @type {CampaignMessageContentType[]} */
export const CAMPAIGN_MESSAGE_CONTENT_TYPES = [
	CAMPAIGN_MESSAGE_CONTENT_TYPE_SMS,
	CAMPAIGN_MESSAGE_CONTENT_TYPE_VIBER,
	CAMPAIGN_MESSAGE_CONTENT_TYPE_PUSH_NOTIFICATION,
	CAMPAIGN_MESSAGE_CONTENT_TYPE_EMAIL,
];
/** @type {CampaignMessageContentType[]} */
export const CAMPAIGN_MESSAGE_CONTENT_TYPES_WITH_ALL = [
	CAMPAIGN_MESSAGE_CONTENT_TYPE_ALL,
	CAMPAIGN_MESSAGE_CONTENT_TYPE_SMS,
	CAMPAIGN_MESSAGE_CONTENT_TYPE_VIBER,
	CAMPAIGN_MESSAGE_CONTENT_TYPE_PUSH_NOTIFICATION,
	CAMPAIGN_MESSAGE_CONTENT_TYPE_EMAIL,
];
/** @type {Object<CampaignMessageContentType, {symbol: string, [symbolPrefix]: string}>} */
export const CAMPAIGN_MESSAGE_CONTENT_TYPE_ICONS = {
	[CAMPAIGN_MESSAGE_CONTENT_TYPE_SMS]: {symbol: 'ui-messaging', symbolPrefix: 'icofont-'},
	[CAMPAIGN_MESSAGE_CONTENT_TYPE_VIBER]: {symbol: 'brand-viber', symbolPrefix: 'icofont-'},
	[CAMPAIGN_MESSAGE_CONTENT_TYPE_PUSH_NOTIFICATION]: {symbol: 'notification', symbolPrefix: 'icofont-'},
	[CAMPAIGN_MESSAGE_CONTENT_TYPE_EMAIL]: {symbol: 'email', symbolPrefix: 'icofont-'},
};

/** @typedef {string} MessageDefinitionStatus */
export const MESSAGE_DEFINITION_STATUS_IN_PREPARATION = 'IN_PREPARATION';
export const MESSAGE_DEFINITION_STATUS_PUBLISHED = 'PUBLISHED';
/** @enum {MessageDefinitionStatus} */
export const MESSAGE_DEFINITION_STATUS = {
	IN_PREPARATION: MESSAGE_DEFINITION_STATUS_IN_PREPARATION,
	PUBLISHED: MESSAGE_DEFINITION_STATUS_PUBLISHED,
};
/** @type {MessageDefinitionStatus[]} */
export const MESSAGE_DEFINITION_STATUSES = [
	MESSAGE_DEFINITION_STATUS_IN_PREPARATION,
	MESSAGE_DEFINITION_STATUS_PUBLISHED,
];

/** @typedef {string} MessageDefinitionInterval */
export const MESSAGE_DEFINITION_INTERVAL_HOUR = 'HOUR';
export const MESSAGE_DEFINITION_INTERVAL_DAY = 'DAY';
export const MESSAGE_DEFINITION_INTERVAL_WEEK = 'WEEK';
export const MESSAGE_DEFINITION_INTERVAL_MONTH = 'MONTH';
export const MESSAGE_DEFINITION_INTERVAL_YEAR = 'YEAR';
/** @enum {MessageDefinitionInterval} */
export const MESSAGE_DEFINITION_INTERVAL = {
	HOUR: MESSAGE_DEFINITION_INTERVAL_HOUR,
	DAY: MESSAGE_DEFINITION_INTERVAL_DAY,
	WEEK: MESSAGE_DEFINITION_INTERVAL_WEEK,
	MONTH: MESSAGE_DEFINITION_INTERVAL_MONTH,
	YEAR: MESSAGE_DEFINITION_INTERVAL_YEAR,
};
/** @type {MessageDefinitionInterval[]} */
export const MESSAGE_DEFINITION_INTERVALS = [
	MESSAGE_DEFINITION_INTERVAL_HOUR,
	MESSAGE_DEFINITION_INTERVAL_DAY,
	MESSAGE_DEFINITION_INTERVAL_WEEK,
	MESSAGE_DEFINITION_INTERVAL_MONTH,
	MESSAGE_DEFINITION_INTERVAL_YEAR,
];

export const PUBLISH_MESSAGE_DEFINITION_ERROR_CODE = '118017001';
export const PUBLISH_CAMPAIGN_MESSAGE_DEFINITION_ERROR_CODE = '118016001';

/** @typedef {string} PublishMessageDefinitionError */
export const PUBLISH_MESSAGE_DEFINITION_ERROR_MISSING_VALUE = 'MISSING_VALUE';
export const PUBLISH_MESSAGE_DEFINITION_ERROR_INVALID_VALUE = 'INVALID_VALUE';
/** @enum {PublishMessageDefinitionError} */
export const PUBLISH_MESSAGE_DEFINITION_ERROR = {
	MISSING_VALUE: PUBLISH_MESSAGE_DEFINITION_ERROR_MISSING_VALUE,
	INVALID_VALUE: PUBLISH_MESSAGE_DEFINITION_ERROR_INVALID_VALUE,
};
/** @type {PublishMessageDefinitionError[]} */
export const PUBLISH_MESSAGE_DEFINITION_ERRORS = [
	PUBLISH_MESSAGE_DEFINITION_ERROR_MISSING_VALUE,
	PUBLISH_MESSAGE_DEFINITION_ERROR_INVALID_VALUE,
];