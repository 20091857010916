/** @typedef {string} CustomerListFileError */
export const CUSTOMER_LIST_FILE_ERROR_MISSING_VALUE = 'MISSING_VALUE';
export const CUSTOMER_LIST_FILE_ERROR_NOT_FOUND_IN_DB = 'NOT_FOUND_IN_DB';
export const CUSTOMER_LIST_FILE_ERROR_INVALID_VALUE = 'INVALID_VALUE';
export const CUSTOMER_LIST_FILE_ERROR_ALREADY_EXISTS_VALUE = 'ALREADY_EXISTS_VALUE';
export const CUSTOMER_LIST_FILE_ERROR_DUPLICATED_VALUE = 'DUPLICATED_VALUE';
/** @enum {CustomerListFileError} */
export const CUSTOMER_LIST_FILE_ERROR = {
	MISSING_VALUE: CUSTOMER_LIST_FILE_ERROR_MISSING_VALUE,
	NOT_FOUND_IN_DB: CUSTOMER_LIST_FILE_ERROR_NOT_FOUND_IN_DB,
	INVALID_VALUE: CUSTOMER_LIST_FILE_ERROR_INVALID_VALUE,
	ALREADY_EXISTS_VALUE: CUSTOMER_LIST_FILE_ERROR_ALREADY_EXISTS_VALUE,
	DUPLICATED_VALUE: CUSTOMER_LIST_FILE_ERROR_DUPLICATED_VALUE,
};
/** @type {CustomerListFileError[]} */
export const CUSTOMER_LIST_FILE_ERRORS = [
	CUSTOMER_LIST_FILE_ERROR_MISSING_VALUE,
	CUSTOMER_LIST_FILE_ERROR_NOT_FOUND_IN_DB,
	CUSTOMER_LIST_FILE_ERROR_INVALID_VALUE,
	CUSTOMER_LIST_FILE_ERROR_ALREADY_EXISTS_VALUE,
	CUSTOMER_LIST_FILE_ERROR_DUPLICATED_VALUE,
];

/**
 * Hardcoded customer fields
 * @type {string[]} 
 */
export const CUSTOMER_FIELDS = [
	'FIRST NAME',
	'LAST NAME',
	'EMAIL',
	'PHONE NUMBER',
	'EXTERNAL CRM ID',
	'DATE OF BIRTH',
];

/** @typedef {string} CustomerMessageStatus */
export const CUSTOMER_MESSAGE_STATUS_IN_PREPARATION = 'IN_PREPARATION';
export const CUSTOMER_MESSAGE_STATUS_PENDING = 'PENDING';
export const CUSTOMER_MESSAGE_STATUS_QUEUED = 'QUEUED';
export const CUSTOMER_MESSAGE_STATUS_WAITING_FOR_DELIVERY = 'WAITING_FOR_DELIVERY';
export const CUSTOMER_MESSAGE_STATUS_DELIVERED = 'DELIVERED';
export const CUSTOMER_MESSAGE_STATUS_UNDELIVERABLE = 'UNDELIVERABLE';
export const CUSTOMER_MESSAGE_STATUS_EXPIRED = 'EXPIRED';
export const CUSTOMER_MESSAGE_STATUS_REJECTED = 'REJECTED';
export const CUSTOMER_MESSAGE_STATUS_ERROR = 'ERROR';
export const CUSTOMER_MESSAGE_STATUS_DUPLICATE = 'DUPLICATE';
export const CUSTOMER_MESSAGE_STATUS_NOT_TRIED = 'NOT_TRIED';
export const CUSTOMER_MESSAGE_STATUS_LINK_OPENED = 'LINK_OPENED';
export const CUSTOMER_MESSAGE_STATUS_MISSING_TO = 'MISSING_TO';
export const CUSTOMER_MESSAGE_STATUS_BLOCKED = 'BLOCKED';
export const CUSTOMER_MESSAGE_STATUS_BLOCKED_BY_CAPPING_PLAN = 'BLOCKED_BY_CAPPING_PLAN';
export const CUSTOMER_MESSAGE_STATUS_BLACKLISTED = 'BLACKLISTED';
export const CUSTOMER_MESSAGE_STATUS_OPENED = 'OPENED';
export const CUSTOMER_MESSAGE_STATUS_UNKNOWN = 'UNKNOWN';
/** @enum {CustomerMessageStatus} */
export const CUSTOMER_MESSAGE_STATUS = {
	IN_PREPARATION: CUSTOMER_MESSAGE_STATUS_IN_PREPARATION,
	PENDING: CUSTOMER_MESSAGE_STATUS_PENDING,
	QUEUED: CUSTOMER_MESSAGE_STATUS_QUEUED,
	WAITING_FOR_DELIVERY: CUSTOMER_MESSAGE_STATUS_WAITING_FOR_DELIVERY,
	DELIVERED: CUSTOMER_MESSAGE_STATUS_DELIVERED,
	UNDELIVERABLE: CUSTOMER_MESSAGE_STATUS_UNDELIVERABLE,
	EXPIRED: CUSTOMER_MESSAGE_STATUS_EXPIRED,
	REJECTED: CUSTOMER_MESSAGE_STATUS_REJECTED,
	ERROR: CUSTOMER_MESSAGE_STATUS_ERROR,
	DUPLICATE: CUSTOMER_MESSAGE_STATUS_DUPLICATE,
	NOT_TRIED: CUSTOMER_MESSAGE_STATUS_NOT_TRIED,
	LINK_OPENED: CUSTOMER_MESSAGE_STATUS_LINK_OPENED,
	MISSING_TO: CUSTOMER_MESSAGE_STATUS_MISSING_TO,
	BLOCKED: CUSTOMER_MESSAGE_STATUS_BLOCKED,
	BLOCKED_BY_CAPPING_PLAN: CUSTOMER_MESSAGE_STATUS_BLOCKED_BY_CAPPING_PLAN,
	BLACKLISTED: CUSTOMER_MESSAGE_STATUS_BLACKLISTED,
	OPENED: CUSTOMER_MESSAGE_STATUS_OPENED,
	UNKNOWN: CUSTOMER_MESSAGE_STATUS_UNKNOWN,
};
/** @type {CustomerMessageStatus[]} */
export const CUSTOMER_MESSAGE_STATUSES = [
	CUSTOMER_MESSAGE_STATUS_IN_PREPARATION,
	CUSTOMER_MESSAGE_STATUS_PENDING,
	CUSTOMER_MESSAGE_STATUS_QUEUED,
	CUSTOMER_MESSAGE_STATUS_WAITING_FOR_DELIVERY,
	CUSTOMER_MESSAGE_STATUS_DELIVERED,
	CUSTOMER_MESSAGE_STATUS_UNDELIVERABLE,
	CUSTOMER_MESSAGE_STATUS_EXPIRED,
	CUSTOMER_MESSAGE_STATUS_REJECTED,
	CUSTOMER_MESSAGE_STATUS_ERROR,
	CUSTOMER_MESSAGE_STATUS_DUPLICATE,
	CUSTOMER_MESSAGE_STATUS_NOT_TRIED,
	CUSTOMER_MESSAGE_STATUS_MISSING_TO,
	CUSTOMER_MESSAGE_STATUS_OPENED,
	CUSTOMER_MESSAGE_STATUS_LINK_OPENED,
	CUSTOMER_MESSAGE_STATUS_BLOCKED,
	CUSTOMER_MESSAGE_STATUS_BLOCKED_BY_CAPPING_PLAN,
	CUSTOMER_MESSAGE_STATUS_BLACKLISTED,
	CUSTOMER_MESSAGE_STATUS_UNKNOWN,
];
/** @type {Object<CustomerMessageStatus, string>} */
export const CUSTOMER_MESSAGE_STATUS_CLASS_MAP = {
	[CUSTOMER_MESSAGE_STATUS_IN_PREPARATION]: 'default',
	[CUSTOMER_MESSAGE_STATUS_PENDING]: 'default',
	[CUSTOMER_MESSAGE_STATUS_QUEUED]: 'default',
	[CUSTOMER_MESSAGE_STATUS_WAITING_FOR_DELIVERY]: 'default',
	[CUSTOMER_MESSAGE_STATUS_DELIVERED]: 'success',
	[CUSTOMER_MESSAGE_STATUS_UNDELIVERABLE]: 'error',
	[CUSTOMER_MESSAGE_STATUS_EXPIRED]: 'error',
	[CUSTOMER_MESSAGE_STATUS_REJECTED]: 'error',
	[CUSTOMER_MESSAGE_STATUS_ERROR]: 'error',
	[CUSTOMER_MESSAGE_STATUS_DUPLICATE]: 'error',
	[CUSTOMER_MESSAGE_STATUS_NOT_TRIED]: 'default',
	[CUSTOMER_MESSAGE_STATUS_LINK_OPENED]: 'success',
	[CUSTOMER_MESSAGE_STATUS_MISSING_TO]: 'error',
	[CUSTOMER_MESSAGE_STATUS_BLOCKED]: 'error',
	[CUSTOMER_MESSAGE_STATUS_BLACKLISTED]: 'error',
	[CUSTOMER_MESSAGE_STATUS_OPENED]: 'success',
};