import React from 'react';
import BaseComponent from 'Core/components/BaseComponent';
import AdvancedDropdown from 'Core/components/display/AdvancedDropdown';
import DownloadButton, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from 'Core/components/action/DownloadButton';
import * as pageConfig from 'Pages/apps/default/blacklists/config';
import {getIOUrl} from 'Core/io/helper';
import PropTypes from 'prop-types';
import {getDateString} from 'Core/helpers/datetime';
import {getStringForFileName} from 'Core/helpers/data';
import Button from 'Core/components/display/Button';
import {icon_font_loading_symbol} from 'Config/app';
import {showErrorMessage} from 'Core/helpers/message';

class DownloadBlacklistCustomersButton extends BaseComponent {
	constructor(props) {
		super(props, {
			domPrefix: 'download-blacklist-customers-button',
			translationPath: `${pageConfig.translationPath}.ItemPopup`, 
		});
		
		this.state = {
			downloading: false,
		};
	}
	
	render() {
		const {blacklistsId, blacklistsName, ...props} = this.props;
		const {downloading} = this.state;
		
		return (
			<AdvancedDropdown 
				{...props}
				label={<Button
					icon={downloading ? icon_font_loading_symbol : 'download'}
					iconProps={{spin: downloading}}
					label={this.t('download')}
					tooltip={this.t('download_tooltip')}
					displayStyle={BUTTON_STYLE.ACTION}
					displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
				/>}
				disabled={downloading}
			>
				<DownloadButton 
					className="dropdown-item" 
					icon="download" 
					label={this.t('download_without_customer_custom_field')}
					displayType={BUTTON_DISPLAY_TYPE.NONE}
					displayStyle={BUTTON_STYLE.ACTION}
					url={getIOUrl(
						'defaultAuthorizedApi', 
						`blacklist/export-black-lists?blackListId=${blacklistsId}&fetchCustomFields=false`
					)}
					filename={this.t('download_filename_no_custom_filed', '', '', {
						date: getDateString(new Date(), 'yyyy-MM-dd-HH-mm-ss'),
						blacklist: getStringForFileName(blacklistsName),
						ext: '.csv',
					})}
					method="GET"
					onClick={() => this.setState({downloading: true})}
					onDownload={() => this.setState({downloading: false})}
					onDownloadError={() => {
						showErrorMessage(this.t('download_error'));
						return this.setState({downloading: false});
					}}
				/>
				<DownloadButton
					className="dropdown-item"
					icon="download" 
					label={this.t('download_with_customer_custom_field')}
					displayType={BUTTON_DISPLAY_TYPE.NONE}
					displayStyle={BUTTON_STYLE.ACTION}
					url={getIOUrl(
						'defaultAuthorizedApi',
						`blacklist/export-black-lists?blackListId=${blacklistsId}&fetchCustomFields=true`
					)}
					filename={this.t('download_filename_custom_filed', '', '', {
						date: getDateString(new Date(), 'yyyy-MM-dd-HH-mm-ss'),
						blacklist: getStringForFileName(blacklistsName),
						ext: '.csv',
					})}
					method="GET"
					onClick={() => this.setState({downloading: true})}
					onDownload={() => this.setState({downloading: false})}
					onDownloadError={() => {
						showErrorMessage(this.t('download_error'));
						return this.setState({downloading: false});
					}}
				/>
			</AdvancedDropdown>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
DownloadBlacklistCustomersButton.propTypes = {
	...AdvancedDropdown.propTypes,

	label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	blacklistsId: PropTypes.string,
	blacklistsName: PropTypes.string,
};

export default DownloadBlacklistCustomersButton;