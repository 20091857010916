import React from "react";
import BaseComponent, {executeComponentCallback} from 'Core/components/BaseComponent';
import {connect} from 'react-redux';
import {getPageActions} from 'Core/helpers/redux';
import SelectInput from 'Core/components/input/SelectInput';
import {omit, sortBy} from 'lodash';
import PropTypes from 'prop-types';
import {getArray, getBoolean, getString} from 'Core/helpers/data';
import {STATISTICS_FILTER_NO_CAMPAIGN} from 'Pages/apps/default/statistics/const';

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getPageActions();

class MessageDefinitionSelectInput extends BaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			/** @type {MessageDefinitionDataObject[]|null|undefined} */
			messageDefinitionList: null,
			/** @type {boolean} */
			messageDefinitionListLoading: false,
		};

		// Data methods
		this.loadOptions = this.loadOptions.bind(this);
	}

	/** @inheritDoc */
	async asyncComponentDidMount() {
		await super.asyncComponentDidMount();

		// Load select options
		await this.loadOptions();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const campaignId = getString(this.props, 'campaign.id');
		const prevCampaignId = getString(prevProps, 'campaign.id');
			
		// Re-load select options when campaign ID or client ID changes
		if (campaignId !== prevCampaignId || this.props.clientId !== prevProps.clientId) {
			if (!campaignId || campaignId === STATISTICS_FILTER_NO_CAMPAIGN) {
				this.setState({messageDefinitionList: []}).then(() => executeComponentCallback(this.props.onChange, null));
			} else {
				this.loadOptions().then();
			}
		}
	}

	/**
	 * Load select options
	 * @param {boolean} [onMount=false] - Specifies if this method is called as part of the component mount cycle.
	 * @return {Promise<void>}
	 */
	async loadOptions(onMount = false) {
		const {clientId, fetchMessageDefinitionCodebookAction} = this.props;
		const campaignId = getString(this.props, 'campaign.id');
		const executeAbortableAction = (onMount ? this.executeAbortableActionMount : this.executeAbortableAction);
		
		if (!!campaignId && campaignId !== STATISTICS_FILTER_NO_CAMPAIGN) {
			await this.setState({messageDefinitionListLoading: true})
				.then(() => executeAbortableAction(fetchMessageDefinitionCodebookAction, campaignId, clientId))
				.then(messageDefinitionList => this.setState({messageDefinitionList}))
				.finally(() => this.setState({messageDefinitionListLoading: false}));
		}
	}
	
	render() {
		/** @type {CampaignListItemDataObject} */
		const campaign = this.getProp('campaign');
		const campaignId = getString(campaign, 'id');
		const {messageDefinitionListLoading} = this.state;
		const messageDefinitionList = sortBy(getArray(this.state, 'messageDefinitionList'), ['ordinal']);
		
		return (
			<SelectInput
				{...omit(this.props, [
					'isDisabled', 'campaign', 'clientId', ...Object.keys(allActions)
				])}
				primaryKey="id"
				options={!!messageDefinitionList ? messageDefinitionList : []}
				isLoading={messageDefinitionListLoading}
				getOptionValue={o => o.id}
				getOptionLabel={o => `${getString(campaign, 'name')} - ${getString(o, 'ordinal')}`}
				isDisabled={
					!campaignId || campaignId === STATISTICS_FILTER_NO_CAMPAIGN || getBoolean(this.props, 'isDisabled')
				}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
MessageDefinitionSelectInput.propTypes = {
	...SelectInput.propTypes,

	campaign: PropTypes.object,
	clientId: PropTypes.string,
};

/**
 * Define component default values for own props
 */
MessageDefinitionSelectInput.defaultProps = {
	...SelectInput.defaultProps,
};

export default connect(null, allActions, null, {forwardRef: true})(MessageDefinitionSelectInput);