import {getNumber, getString} from "Core/helpers/data";
import {ProjectListItemDataObject} from 'DataObjects/project';

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {ProjectListItemDataObject}
 */
export const input = rawData => new ProjectListItemDataObject(
	getString(rawData, 'id'),
	getString(rawData, 'code'),
	getString(rawData, 'name'),
	getString(rawData, 'description'),
	getString(rawData, 'icon'),
	getNumber(rawData, 'ordinal')
);