export class CampaignWizardDataObject {
	/**
	 *
	 * @param {string} [projectId=''] - DB ID of the campaign project.
	 * @param {string} [name=''] - Campaign name.
	 * @param {string} [description=''] - Campaign description.
	 * @param {?Date} [startDate=null] - Campaign and message definition start date.
	 * @param {?Date} [endDate=null] - Campaign end date.
	 * @param {ChannelDataObject[]} channels - Campaign channels.
	 */
	constructor(projectId = '', name = '', description = '', startDate = null, endDate = null, channels = []) {
		this.projectId = projectId;
		this.name = name;
		this.description = description;
		this.startDate = startDate;
		this.endDate = endDate;
		this.channels = channels;
	}
}